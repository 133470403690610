<template>
  <div>
    <div class="row align-items-stratch">
      <div class="col-xl-3 my-3">
        <label for=""> Employee</label>
        <select class="form-select" v-model="appointment.employee_reference">
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 my-3">
        <label for=""> Client </label>
        <select
          class="form-select"
          v-model="appointment.customer_reference"
          :disabled="$route.query.customer_reference ? true : false"
        >
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 my-3">
        <label for=""> Fournisseur </label>
        <select class="form-select" v-model="appointment.supplier_reference">
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 my-3">
        <label for=""> Reparation </label>
        <select class="form-select" v-model="appointment.repair_reference"
        :disabled="$route.query.repair_reference ? true : false"
        >
          <option
            v-for="(repair, index) in repairs"
            :key="index++"
            :value="repair.reference"
          >
            {{ repair.reference }} - {{ repair.date }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 my-3">
        <label for=""> Commande </label>
        <select class="form-select" v-model="appointment.order_reference">
          <option
            v-for="(order, index) in orders"
            :key="index++"
            :value="order.reference"
          >
            {{ order.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 my-3">
        <label for=""> Date </label>
        <input type="date" class="form-control" v-model="appointment.date" />
      </div>

      <div class="col-xl-3 my-3">
        <label for=""> Heure </label>
        <input type="time" class="form-control" v-model="appointment.time" />
      </div>
    </div>

    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(appointment)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      appointment: {
        customer_reference: this.$route.query.customer_reference || "",
        repair_reference: this.$route.query.repair_reference || "",
      },
      file: null,
      imageUrl: "",
    };
  },
  computed: {
    ...mapGetters("appointment", {
      appointments: "getAll",
    }),
    ...mapGetters("repair", {
      repairs: "getAll",
    }),
    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("order", {
      orders: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("appointment/store", data);
    },
  },
  async beforeMount() {
    await this.$store.dispatch("employee/getAll");
    await this.$store.dispatch("order/getAll");
    await this.$store.dispatch("customer/getAll");
    await this.$store.dispatch("supplier/getAll");
    await this.$store.dispatch("repair/getAll");
  },
};
</script>
